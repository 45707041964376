.contact-main-banner {
  background-image: url("../imgs/bygget2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 100%;
  height: 850px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .contact-main-banner {
    background-repeat: no-repeat;
    background-position: 15% 75%;
    height: 300px;
  }
}
